import React, { useState } from "react";
import PageWrapper from "../../../../Components/Wrapper/Wrapper";
import Select from "react-select";
import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../../../../../../setup/axios";
import Constants from "../../../../../../Utils/Constants";
import { dotSpinner } from "ldrs";

function Kycmain() {
  const [address, setAddress] = useState("");
  //eslint-disable-next-line
  const [country, setCountry] = useState("Ghana");
  const [region, setRegion] = useState("");
  const [city, setCity] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedGender, setSelectedGender] = useState(null);
  dotSpinner.register();

  const hasErrors =
    !address || !selectedRegion || !selectedGender || !dob || !city;

  const handleAddress = (e: any) => {
    setAddress(e.target.value);
  };

  const handleCity = (e: any) => {
    setCity(e.target.value);
  };

  const handleRegion = (selectedRegion: any) => {
    setSelectedRegion(selectedRegion);
    setRegion(selectedRegion.value);
  };

  const handleGender = (selectedGender: any) => {
    setSelectedGender(selectedGender);
    setGender(selectedGender.value);
  };

  const handleDob = (e: any) => {
    setDob(e.target.value);
  };

  const handleSubmit = () => {
    submitData();
  };

  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ];

  const regionOptions = [
    { value: "Greater Accra", label: "Greater Accra" },
    { value: "Ashanti Region", label: "Ashanti Region" },
    { value: "Bono", label: "Bono" },
    { value: "Bono East", label: "Bono East" },
    { value: "Ahafo", label: "Ahafo" },
    { value: "Central", label: "Central" },
    { value: "Eastern", label: "Eastern" },
    { value: "Northern", label: "Northern" },
    { value: "Savanna", label: "Savanna" },
    { value: "North East", label: "North East" },
    { value: "Upper East", label: "Upper East" },
    { value: "Upper West", label: "Upper West" },
    { value: "Volta", label: "Volta" },
    { value: "Oti", label: "Oti" },
    { value: "Western", label: "Western" },
    { value: "Western North", label: "Western North" },
  ];

  const selectStyles = {
    control: (styles: any) => ({
      ...styles,
      border: "1px solid #E0E0E0",
      height: "53px",
      borderRadius: "10px",
    }),
  };

  const customTheme = (theme: any) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "lightblue",
      primary: "#E5E4E2",
      textAlign: "left",
    },
  });

  const { mutate: submitData, isPending } = useMutation({
    mutationFn: async () => {
      const response = await axiosInstance.patch(
        `${Constants.API_BASE_URL}/users`,
        {
          dateOfBirth: dob,
          gender: gender,
          country: country,
          state: region,
          address: address,
          city: city,
        }
      );
      return response?.data;
    },
    onSuccess: () => {
      initiateKyc();
    },
  });

  const { mutate: initiateKyc, isPending: isLoadingPost } = useMutation({
    mutationFn: async () => {
      const response = await axiosInstance.post(
        `${Constants.API_BASE_URL}/users/kyc/initiate`
      );
      return response?.data;
    },
    onSuccess: (kycdata) => {
      if (kycdata?.verificationUrl) {
        window.open(kycdata.verificationUrl, "_blank");
      }
    },
  });

  return (
    <PageWrapper
      content={
        <div>
          <div className="text-xl font-bold text-left mt-8 md:px-0 px-4 ">
            Personal Details
          </div>
          {isPending && isLoadingPost ? (
            <div className="flex flex-col justify-center items-center min-h-[250px] min-w-[250px] ">
              <div className="mb-4">
                <l-dot-spinner
                  size="60"
                  speed="0.9"
                  color="black"
                ></l-dot-spinner>
              </div>
              <div>Loading...</div>
            </div>
          ) : (
            <div className="md:mt-12 mt-8 md:p-0 p-4 w-full">
              <div className="flex flex-col lg:flex-row items-start justify-between w-full gap-4">
                {/* Address Field */}
                <div className="w-full lg:w-[48%] flex flex-col items-start">
                  <label
                    htmlFor="address"
                    className="text-left text-gray-700 font-semibold"
                  >
                    Address
                  </label>
                  <input
                    id="address"
                    type="text"
                    placeholder="Enter address"
                    value={address}
                    onChange={handleAddress}
                    className="w-full h-[53px] rounded-[10px] border border-gray-300 outline-none p-5 mt-2 focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                {/* Country Field */}
                <div className="w-full lg:w-[48%] flex flex-col items-start">
                  <label
                    htmlFor="country"
                    className="text-left text-gray-700 font-semibold"
                  >
                    Country
                  </label>
                  <input
                    id="country"
                    type="text"
                    placeholder="Enter country"
                    value={country}
                    className="w-full h-[53px] rounded-[10px] border border-gray-300 outline-none p-5 mt-2  cursor-not-allowed"
                    readOnly
                  />
                </div>
              </div>

              <div className="flex flex-col lg:flex-row items-start justify-between w-full mt-8 gap-4">
                {/* Region Selector */}
                <div className="w-full lg:w-[48%] flex flex-col items-start">
                  <label
                    htmlFor="region"
                    className="text-left text-gray-700 font-semibold"
                  >
                    Region
                  </label>
                  <div className="w-full h-[53px] outline-none mt-2">
                    <Select
                      id="region"
                      formatOptionLabel={({ value, label }) => (
                        <div className="flex items-start">
                          <span>{label}</span>
                        </div>
                      )}
                      options={regionOptions}
                      onChange={handleRegion}
                      placeholder={
                        <div className="flex items-start">
                          <span className="text-[14px] font-medium ml-2 mt-1">
                            Select Region
                          </span>
                        </div>
                      }
                      styles={selectStyles}
                      theme={customTheme}
                      isSearchable={false}
                      blurInputOnSelect={false}
                    />
                  </div>
                </div>
                {/* City Input */}
                <div className="w-full lg:w-[48%] flex flex-col items-start">
                  <label
                    htmlFor="city"
                    className="text-left text-gray-700 font-semibold"
                  >
                    City
                  </label>
                  <input
                    id="city"
                    type="text"
                    placeholder="Enter a City"
                    value={city}
                    onChange={handleCity}
                    className="w-full h-[53px] rounded-[10px] border border-gray-300 outline-none p-5 mt-2 focus:ring-2 focus:ring-blue-500"
                  />
                </div>
              </div>

              <div className="flex flex-col lg:flex-row items-start justify-between w-full mt-8 gap-4">
                {/* Gender Selector */}
                <div className="w-full lg:w-[48%] flex flex-col items-start">
                  <label
                    htmlFor="gender"
                    className="text-left text-gray-700 font-semibold"
                  >
                    Gender
                  </label>
                  <div className="w-full h-[53px] outline-none mt-2">
                    <Select
                      id="gender"
                      formatOptionLabel={({ value, label }) => (
                        <div className="flex items-start">
                          <span>{label}</span>
                        </div>
                      )}
                      options={genderOptions}
                      onChange={handleGender}
                      placeholder={
                        <div className="flex items-start">
                          <span className="text-[14px] font-medium ml-2 mt-1">
                            Select Gender
                          </span>
                        </div>
                      }
                      styles={selectStyles}
                      theme={customTheme}
                      isSearchable={false}
                      blurInputOnSelect={false}
                    />
                  </div>
                </div>
                {/* Date of Birth Input */}
                <div className="w-full lg:w-[48%] flex flex-col items-start">
                  <label
                    htmlFor="dob"
                    className="text-left text-gray-700 font-semibold"
                  >
                    Date of Birth
                  </label>
                  <input
                    id="dob"
                    type="date"
                    onChange={handleDob}
                    value={dob}
                    className="w-full h-[53px] rounded-[10px] border border-gray-300 outline-none p-5 mt-2 focus:ring-2 focus:ring-blue-500"
                  />
                </div>
              </div>

              <div className="flex items-start mt-12 w-full lg:w-[48%] ">
                <button
                  className={`${
                    hasErrors
                      ? "bg-gray-200 text-gray-300"
                      : "bg-blue text-white"
                  } border flex rounded-[10px] lg:px-4 py-4 mt-6 items-center justify-center w-full mb-4 cursor-pointer`}
                  disabled={hasErrors}
                  onClick={handleSubmit}
                >
                  Save and Continue
                </button>
              </div>
            </div>
          )}
        </div>
      }
    />
  );
}

export default Kycmain;
