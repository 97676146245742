import React, { useEffect, useState } from "react";
import PageWrapper from "../Components/Wrapper/Wrapper";
import Constants from "../../../Utils/Constants";
import axiosInstance from "../../../setup/axios";
import Pagination from "./Pagination";
import Cookies from "js-cookie";
import { orderInfoData } from "../Components/NewOrder/types";
import uparrow from "../../../Common/Assets/Images/uparrow.png";
import downarrow from "../../../Common/Assets/Images/downarrow.png";
import { useParams, useNavigate } from "react-router-dom";
import LineLoading from "../../../Common/Components/Loading/LineLoading";
import dayjs from "dayjs";
import { FolderOpen } from "solar-icon-set/folders";
import Loading from "../../../Common/Components/Loading/Loading"; // Ensure this import is correct

function Orders() {
  function getStatusColorClass(status: string) {
    switch (status) {
      case "awaiting.approval":
        return "inline-block bg-green-200 text-green-500 px-3 rounded-full text-sm align-middle ";
      case "approved":
      case "pending":
        return "bg-orange-200 text-orange-500 inline-block align-middle  px-3 rounded-full text-sm";
      case "sending":
        return "bg-purple-200 inline-block align-middle  text-purple-500 px-3 rounded-full text-sm";
      case "completed":
        return "bg-green-200 inline-block align-middle  text-green-500 px-3 rounded-full text-sm";
      case "canceled":
        return "bg-red-200 inline-block align-middle text-red-500 px-3 rounded-full text-sm";
      case "expired":
        return "bg-gray-200 inline-block align-middle text-gray-500 px-3 rounded-full text-sm";
      default:
        return ""; // Default style
    }
  }

  const Content = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [isRequesting, setRequesting] = useState({
      status: false,
      text: "",
    });
    const [orders, setOrders] = useState([]);
    const [buyOrders, setBuyOrders] = useState([]);
    const [sellOrders, setSellOrders] = useState([]);

    const [state, setState] = useState({
      currentPage: 1,
      totalList: 0,
      total: 0,
      show: "all",
      showOrderInfo: {
        status: false,
        data: {},
      },
      page: 1,
      paymentMethod: {},
    });

    function fetchOrders(type: string, page: number) {
      setRequesting({ status: true, text: "line" });
      setState((prev) => ({
        ...prev,
        page: page,
      }));

      let url = `${Constants.API_BASE_URL}/users/orders?limit=10&skip=${(page - 1) * 10}`;
      if (type !== "all") {
        url += `&type=${type}`;
      }

      axiosInstance
        .get(url)
        .then((res) => {
          setRequesting({ status: false, text: "" });
          let data = res.data.data;
          setOrders(data);
          Cookies.set("orders", JSON.stringify(data));

          if (type === "buy") {
            setBuyOrders(data);
          } else if (type === "sell") {
            setSellOrders(data);
          }

          setState((prev) => ({
            ...prev,
            total: res.data?.total,
            totalList: res.data.total,
          }));
        })
        .catch(() => {
          setRequesting({ status: false, text: "" });
        });
    }

    useEffect(() => {
      if (params.type) {
        setState((prev) => ({
          ...prev,
          show: params.type || "all",
        }));
        fetchOrders(params.type, 1); // Start from the first page for the specific type
      } else {
        fetchOrders("all", state.page);
      }

      // eslint-disable-next-line
    }, [params]);

    return (
      <div className="w-full mx-auto min-h-[calc(100%-50px)] flex flex-col items-start mb-10 md:px-0 px-4">
        {isRequesting.status && isRequesting.text !== "line" && (
          <Loading loadText={isRequesting.text || "Loading..."} />
        )}

        <div className="my-6 mb-6 flex flex-col items-start">
          <h1 className="font-semibold text-3xl">Your Orders</h1>
          <p className="text-gray-400 text-[0.9.5rem] flex items-center">
            Table showing page {state.page} of all your
            <b className="text-blue ml-1">
              {state.show === "all"
                ? ` orders`
                : state.show === "buy"
                  ? ` buy orders`
                  : ` sell orders`}
            </b>
          </p>
        </div>

        <section className="walletHistory flex flex-col w-[100%] mx-auto">
          <div className="w-full flex mb-5 items-start">
            <div
              onClick={() => {
                navigate(`/orders/all/1`);
                setState((prev) => ({ ...prev, show: "all", page: 1 }));
              }}
              className={`cursor-pointer border-blue text-black flex j-c-c px-10 py-1 h-[30px] mr-3 font-semibold ${
                state.show === "all"
                  ? "bg-black/10 text-black"
                  : "bg-transparent "
              } rounded-full`}
            >
              All
            </div>
            <div
              onClick={() => {
                navigate(`/orders/buy/1`);
                setState((prev) => ({ ...prev, show: "buy", page: 1 }));
              }}
              className={`cursor-pointer border-blue text-black flex j-c-c px-10 py-1 h-[30px] mr-3 font-semibold ${
                state.show === "buy"
                  ? "bg-black/10 text-black"
                  : "bg-transparent "
              } rounded-full`}
            >
              Buy
            </div>
            <div
              onClick={() => {
                navigate(`/orders/sell/1`);
                setState((prev) => ({ ...prev, show: "sell", page: 1 }));
              }}
              className={`cursor-pointer border-blue text-black flex j-c-c px-10 py-1 h-[30px] mr-3 font-semibold ${
                state.show === "sell"
                  ? "bg-black/10 text-black"
                  : "bg-transparent "
              } rounded-full`}
            >
              Sell
            </div>
          </div>

          <LineLoading
            isOn={isRequesting.status && isRequesting.text === "line"}
          />

          <div className="flex flex-col items-start w-full ">
            {!isRequesting.status &&
              ((state.show === "all"
                ? orders
                : state.show === "buy"
                  ? buyOrders
                  : sellOrders
              ).length === 0 ? (
                <div className="w-full py-[5rem] flex flex-col items-center justify-center">
                  <FolderOpen
                    color="#040406"
                    size={40}
                    iconStyle="Bold"
                    className="mb-5"
                  />
                  {state.show === "all" ? (
                    <p>No Order Yet</p>
                  ) : state.show === "buy" ? (
                    <p>No Buy Order here, Check other pages</p>
                  ) : (
                    <p>
                      No Sell Order here, <br />
                      Check other pages
                    </p>
                  )}
                </div>
              ) : (
                (state.show === "all"
                  ? orders
                  : state.show === "buy"
                    ? buyOrders
                    : sellOrders
                ).map((order: orderInfoData, idx: number) => (
                  <aside
                    onClick={() =>
                      order.type === "sell"
                        ? navigate("/sell-details", { state: { order } })
                        : navigate("/buy-details", { state: { order } })
                    }
                    key={idx}
                    className="flex j-c-b w-full py-3 border-b-[1px] cursor-pointer hover:bg-[#e4e4e463]"
                  >
                    <div className="flex">
                      <div className="relative justify-center w-[35px] h-[35px] mr-4 rounded-[20px]">
                        <img
                          src={order?.assetEntity?.icon}
                          alt=""
                          className="w-8 h-8"
                        />
                        {order.type === "sell" ? (
                          <img
                            src={downarrow}
                            alt=""
                            className=" absolute w-[15px] h-[15px] right-0 bottom-0  z-10"
                          />
                        ) : (
                          <img
                            src={uparrow}
                            alt=""
                            className=" absolute w-[15px] h-[15px] right-0 bottom-0  z-10"
                          />
                        )}
                      </div>
                      <div className="flex flex-col items-start">
                        <p>{order.asset}</p>
                        <div className="flex">
                          <small className="text-gray-400">
                            {dayjs(new Date(order.createdAt)).format(
                              "MMM D, YYYY hh:mm a",
                            )}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col items-end">
                      <p className="font-semibold">$ {order.amount}</p>
                      <small
                        className={`my-2 ${getStatusColorClass(order.status)}`}
                      >
                        {order.status}
                      </small>
                    </div>
                  </aside>
                ))
              ))}
          </div>
        </section>

        {state.totalList > 9 && (
          <Pagination
            isRequesting={isRequesting.status}
            total={state.total}
            setPage={(page: number) => fetchOrders(state.show, page)}
          />
        )}
      </div>
    );
  };

  return <PageWrapper content={<Content />} />;
}

export default Orders;
