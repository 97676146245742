import React from "react";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";

import bank from "../../../Common/Assets/Images/bank.png";
import mobilemoney from "../../../Common/Assets/Images/mobilemoney.png";

interface SecurityProps {
  paymentMethods: any;
  displayMomo: () => void;
  displayBank: () => void;
  payoutMethods: any;
  getOtp: () => void;
  isTwoFactorEnabled: boolean;
  showTwoFactorAuth: () => void;
  disableTwoFactor: () => void;
}

function SecurityTabs({
  paymentMethods,
  displayBank,
  displayMomo,
  payoutMethods,
  getOtp,
  isTwoFactorEnabled,
  showTwoFactorAuth,
  disableTwoFactor,
}: SecurityProps) {
  const categories = [
    {
      name: "Account",
    },
    {
      name: "Payment Methods",
    },
    {
      name: "Security",
    },
  ];

  // console.log("paymentMethods", payoutMethods);
  return (
    <div>
      <div className="flex h-screen w-full items-start  pt-6">
          <TabGroup>
            <TabList className="flex gap-4">
              {categories.map(({ name }) => (
                <Tab
                  key={name}
                  className="rounded-full py-1 px-3 text-sm/6 font-semibold text-black focus:outline-none data-[selected]:bg-black/10 data-[hover]:bg-black/5 data-[selected]:data-[hover]:bg-black/10 data-[focus]:outline-1 data-[focus]:outline-black"
                >
                  {name}
                </Tab>
              ))}
            </TabList>
            <TabPanels className="mt-3">
              {/* account */}
              <TabPanel>
                <div className="flex flex-col mt-6 items-start w-[full] lg:w-[800px]">
                  <div className="text-left font-semibold text-16px">
                    Verifications
                  </div>
                  <div className="border border-gray-200 px-6 py-2 rounded-full mt-4">
                    <div className="flex justify-between w-[full] lg:w-[1000px] ">
                      <div>Email Verification</div>
                      <div className=" bg-green-400 text-white rounded-full px-2 py-1 ml-24 lg:mx-0">
                        Verified
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              {/* payment methods */}
              <TabPanel >
                  {paymentMethods.length === 0 ? (
                     <div className="grid grid-cols-1 gap-2">
                     <div className="w-full lg:w-[1000px] ">
                       {/* Bank Option */}
                       <div
                         className="flex justify-between border border-gray-200 px-6 py-2 rounded-full w-full mt-10 cursor-pointer"
                         onClick={displayBank}
                       >
                         <div className="flex w-full">
                           <img src={bank} alt="Bank Icon" className="h-8 w-8" />
                           <div className="flex flex-col items-start ml-4">
                             <div className="font-semibold">Bank</div>
                             <div className="text-[14px] text-nowrap">
                               Add a bank account to your account
                             </div>
                           </div>
                         </div>
                         <i className="fa fa-caret-right text-gray-500"></i>
                       </div>
                       {/* Mobile Money Option */}
                       <div
                         className="flex justify-between border border-gray-200 px-6 py-2 rounded-full w-full mt-8 cursor-pointer"
                         onClick={displayMomo}
                       >
                         <div className="flex">
                           <img src={mobilemoney} alt="Mobile Money Icon" className="h-8 w-8" />
                           <div className="flex flex-col items-start ml-4">
                             <div className="font-semibold">Mobile Money</div>
                             <div className="text-[14px] text-nowrap">
                               Proceed to add a mobile money account.
                             </div>
                           </div>
                         </div>
                         <i className="fa fa-caret-right text-gray-500"></i>
                       </div>
                     </div>
                   </div>
                  ) : (
                    <div className="flex flex-col mt-6 items-start w-[full] lg:w-[800px]">
                      {/* banks */}

                      <div className="">
                        <div className="text-left font-semibold text-16px mt-6">
                          {" "}
                          Bank Accounts{" "}
                        </div>
                        <div className="flex flex-col mt-6 gap-4">
                          <div className="flex flex-wrap gap-4">
                            <div
                              className="flex cursor-pointer border border-gray-200 px-4 py-3 rounded-full w-full lg:w-[240px] justify-between items-center"
                              onClick={displayBank}
                            >
                              <img src={bank} alt="" className="h-6 w-6" />
                              <div className="whitespace-nowrap ml-2">
                                Add a bank account
                              </div>
                              <div>
                                <i className="fa fa-plus text-gray-500 ml-2 p-3"></i>
                              </div>
                            </div>
                            {payoutMethods.map((payment: any) => (
                              <div
                                key={payment.id}
                                className="flex items-center border border-gray-200 px-4 py-2 rounded-full w-full lg:w-[240px] overflow-hidden"
                              >
                                <img
                                  src={payment.payment.logoUrl}
                                  alt={payment.name}
                                  className="h-8 w-8 rounded-full mr-4 flex-shrink-0"
                                />
                                <div className="flex flex-col items-start flex-grow min-w-0">
                                  <div className="font-semibold text-[14px] text-left whitespace-nowrap overflow-hidden overflow-ellipsis">
                                    {payment.accountName}
                                  </div>
                                  <div className="text-[14px] text-left whitespace-nowrap overflow-hidden overflow-ellipsis">
                                    {payment.accountNumber}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      {/* mobile money */}
                      <div className="">
                        <div className="text-left font-semibold text-16px mt-6">
                          {" "}
                          Momo Accounts{" "}
                        </div>
                        <div className="flex mt-6 flex-wrap gap-4">
                          <div
                            className="flex cursor-pointer border border-gray-200 px-5 py-4 rounded-full w-full lg:w-[240px] justify-between items-center"
                            onClick={displayMomo}
                          >
                            <img src={mobilemoney} alt="" className="h-6 w-6" />
                            <div className="whitespace-nowrap ml-2">
                              Add a momo account
                            </div>
                            <div>
                              <i className="fa fa-plus text-gray-500  p-2"></i>
                            </div>
                          </div>
                          {paymentMethods.map((payment: any) => (
                            <div
                              key={payment.id}
                              className="flex i border border-gray-200 px-4 py-2 rounded-full w-full lg:w-[240px] overflow-hidden"
                            >
                              <img
                                src={payment.payment.logoUrl}
                                alt={payment.name}
                                className="h-8 w-8 rounded-full mr-4 flex-shrink-0"
                              />
                              <div className="flex flex-col items-start flex-grow min-w-0">
                                <div className="font-semibold text-[14px] text-left  break-words">
                                  {payment.accountName}
                                </div>
                                <div className="text-[14px] text-left truncate">
                                  {payment.accountNumber}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
              </TabPanel>
              {/* security */}
              <TabPanel>
                <div className="flex flex-col mt-6 items-start w-full lg:w-[1000px]">
                  <div className="mt-4 w-full">
                    <div className="font-semibold text-left">
                      Change Password
                    </div>
                    <div className="border border-gray-200 px-6 py-2 rounded-full  l mt-6">
                      <div
                        className="flex justify-between w-full p-2 cursor-pointer"
                        onClick={() => getOtp()}
                      >
                        <div>Change Password</div>
                        <div>
                          <i className="fa fa-caret-right text-gray-500"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-6 w-full">
                    <div className="font-semibold  text-left">
                      {isTwoFactorEnabled
                        ? "Two Factor Authentication is Enabled"
                        : "Set Up Two Factor Authentication"}
                    </div>
                  </div>
                  {!isTwoFactorEnabled && (
                    <div className="border border-gray-200 px-6 py-2 rounded-full w-full  mt-6">
                      <div
                        className="flex justify-between w-full p-2 cursor-pointer"
                        onClick={showTwoFactorAuth}
                      >
                        <div>Set Up Two Factor Authentication</div>
                        <div>
                          <i className="fa fa-caret-right text-gray-500"></i>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="mt-6 w-full">
                    <div className="font-semibold text-left">
                      {isTwoFactorEnabled
                        ? " Disable Two Factor Authentication"
                        : "Two Factor Is Disabled"}
                    </div>
                  </div>
                  {isTwoFactorEnabled && (
                    <div className="border border-gray-200 px-6 py-2 rounded-full w-full  mt-6">
                      <div
                        className="flex justify-between w-full p-2 cursor-pointer"
                        onClick={disableTwoFactor}
                      >
                        <div>Disable Two Factor Authentication</div>
                        <div>
                          <i className="fa fa-caret-right text-gray-500"></i>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </TabPanel>
            </TabPanels>
          </TabGroup>
      </div>
    </div>
  );
}

export default SecurityTabs;
