import React, { useState } from "react";
import PageWrapper from "../Components/Wrapper/Wrapper";
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../setup/axios";
import Constants from "../../../Utils/Constants";
import RedeemModal from "./RedeemModal";
import ReferralTabs from "./ReferralTabs";

function ReferralPage() {
  const limit = 10;

  const [showRedeemModal, setShowRedeemModal] = useState(false);

  const fetchUserPoints = async () => {
    const response = await axiosInstance.get(
      `${Constants.API_BASE_URL}/users/referrals/points`,
    );
    return response.data;
  };

  const fetchUserReferrals = async () => {
    const response = await axiosInstance.get(
      `${Constants.API_BASE_URL}/users/referrals?limit=${limit}`,
    );
    return response.data;
  };

  const { data } = useQuery({
    queryKey: ["points"],
    queryFn: fetchUserPoints,
  });

  const { data: referrals } = useQuery({
    queryKey: ["referrals"],
    queryFn: fetchUserReferrals,
  });

  const openRedeemModal = () => {
    setShowRedeemModal(true);
  };
  const closeRedeemModal = () => {
    setShowRedeemModal(false);
  };

  return (
    <PageWrapper
      content={
        <div className="flex flex-col items-start w-full   gap-10 md:px-0 px-5">
          <div>
            <h1 className="font-semibold text-3xl">Referrals</h1>
          </div>
          <div className="flex  flex-col items-start md:flex-row w-full gap-5 md:gap-10">
            <div className="flex min-h-full items-center justify-center w-full ">
              <div className=" max-w-md rounded-[10px] border border-gray-300 p-4 flex flex-col items-start w-full h-[150px]">
                <div className="text-base/7 font-medium text-gray">
                  No. of Referrals
                </div>
                <div className="mt-2 text-[44px] font-bold">
                  {referrals?.total || 0}
                </div>
              </div>
            </div>

            <div className="flex min-h-full items-center justify-center w-full">
              <div className=" max-w-md rounded-[10px] border border-gray-300 p-4 flex flex-col items-start w-full  h-[150px]">
                <div className="text-base/7 font-medium text-gray">
                  Points Earned
                </div>
                <div className="mt-2 text-[37px] md:text-[44px] font-bold whitespace-nowrap ">
                  {data?.points || 0}{" "}
                  <span className="font-bold text-[20px] md:text-[28x] ">
                    {" "}
                    pts
                  </span>
                </div>
                <span>≈ GHS {data?.points / 100 || 0}</span>
              </div>
            </div>

            <div className="flex min-h-full items-center justify-center w-full">
              <div className=" max-w-md rounded-[10px] border border-gray-300 p-4 flex flex-col items-start w-full h-[150px]">
                <div className="text-base/7 font-medium text-gray">
                  Points Redeemed
                </div>
                <div className="mt-2 text-[37px] md:text-[44px] font-bold whitespace-nowrap ">
                  {data?.totalPointsRedeemed || 0}{" "}
                  <span className="font-bold text-[20px] md:text-[28px] ">
                    {" "}
                    pts
                  </span>
                </div>
                <span>≈ GHS {data?.totalPointsRedeemed / 100 || 0}</span>
              </div>
            </div>
          </div>
          {data?.points > 0 && (
            <div
              className="bg-blue flex rounded-[10px] px-2 py-4 mt-6 items-center justify-center w-[250px] md:w-[300px] cursor-pointer"
              onClick={openRedeemModal}
            >
              <p className="text-[15px] text-white">Redeem Points</p>
            </div>
          )}

          <ReferralTabs />

          <RedeemModal
            isOpen={showRedeemModal}
            onClose={closeRedeemModal}
            points={data?.points}
          />
        </div>
      }
    />
  );
}

export default ReferralPage;
