import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageWrapper from "../Components/Wrapper/Wrapper";
// import Market from "./Components/Market/Market";
import UserStats from "./Components/UserStats/UserStats";
// import style from "./style.module.css";
import Constants from "../../../Utils/Constants";
import axiosInstance from "../../../setup/axios";
// import MarketShama from "./Components/Market/MarketShama";
import UserStatsShema from "./Components/UserStats/UserStatsShema";
import Modal from "../Components/Modal/Modal";
import NewOrder from "../Components/NewOrder/NewOrder";
import Banner from "./Components/Banner/Banner";
import InfoOrder from "../Components/NewOrder/InfoOrder";
import { orderInfoData } from "../Components/NewOrder/types";
import kycbanner from "../../../Common/Assets/Images/kycbanner.png";
import challenge from "../../../Common/Assets/Images/challenge.png";
import { useQuery } from "@tanstack/react-query";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import telegram from "../../../Common/Assets/Images/telegram.jpeg";
import closeIcon from "../../../Common/Assets/Images/Close.png";
import Referral from "./Components/Referral";

type dashProp = {
  page?: string;
};

export interface marketType {
  change: string;
  explorer: string;
  id: string;
  marketCapUsd: string;
  maxSupply: string;
  name: string;
  rank: string;
  price: string;
  supply: string;
  code: string;
  volumeUsd24Hr: string;
  vwap24Hr: string;
  icon: string;
}

interface ModalProps {
  onClose: () => void;
}

function Dashboard({ page }: dashProp) {
  const navigate = useNavigate();
  const [data, setData] = useState({
    isRequesting: {
      status: false,
      text: "",
    },
    orderStats: {},
  });
  const [openNewOrder, setOpenNewOrder] = useState(false);
  const [showOrderInfo, setShowInfo] = useState({ status: false, data: {} });
  //eslint-disable-next-line
  const [marketData, setMarketData] = useState<marketType[] | []>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useState(false);

  const handleCheckboxChange = () => {
    setDontShowAgain(true);
    localStorage.setItem("dontShowModalAgain", "true");
  };

  const { data: profileData } = useQuery({
    queryKey: ["user"],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `${Constants.API_BASE_URL}/users/me`
      );
      return response?.data?.accountLevel;
    },
  });
  const urls = [
    "/users/orders/stats",
    "/users/payment/method",
    "/users/me",
    "/assets/crypto/market/data",
  ];
  const fetchData = (index = 0) => {
    if (index >= urls.length) {
      setData((prevData) => ({
        ...prevData,
        isRequesting: { status: false, text: "" },
      }));
      return;
    }

    axiosInstance
      .get(`${Constants.API_BASE_URL}${urls[index]}`)
      .then((res) => {
        switch (index) {
          case 0:
            setData((prevData) => {
              return {
                ...prevData,
                orderStats: { ...prevData.orderStats, ...res.data },
              };
            });
            break;
          case 3:
            //eslint-disable-next-line
            let arr = [{ ...res.data?.bitcoin }, { ...res.data?.tether }];
            // setMarketData(arr)
            // console.log("marketData", res);
            break;
          default:
            break;
        }
        fetchData(index + 1);
      })
      .catch((err) => {
        fetchData(index + 1);
        // console.log(err);
      });
  };

  const fetchMarketData = () => {
    axiosInstance
      .get(`${Constants.API_BASE_URL}/assets/crypto/market/data`)
      .then((res) => {
        setMarketData(res.data);
        // console.log("response", res.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      isRequesting: { status: true, text: "Loading..." },
    }));
    fetchData();
    fetchMarketData();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const seenJoinTelegram = localStorage.getItem("seenJoinTelegram");
    const dontShowModalAgain = localStorage.getItem("dontShowModalAgain");
    if (seenJoinTelegram !== "true" && dontShowModalAgain !== "true") {
      setIsModalOpen(true);
      // localStorage.setItem("seenMobileUpdateModal", "true");
    }
  }, []);

  const joinTelegram = () => {
    
    window.open("https://t.me/+jPFkr8hiNVMyMWU0", "_blank");
    
  }
  const close = () => {
    setIsModalOpen(false);
  };
  const limit = 10;

  const fetchChallenges = async () => {
    const response = await axiosInstance.get(
      `${Constants.API_BASE_URL}/users/monthly/challenges?limit=${limit}`
    );
    return response.data;
  };

  const { data: challenges } = useQuery({
    queryKey: ["points"],
    queryFn: fetchChallenges,
  });

  const MobileUpdateModal: React.FC<ModalProps> = ({ onClose }) => {
    return (
      <Dialog open={true} as="div" className="relative z-10 " onClose={onClose}>
        <div className="fixed inset-0 bg-black bg-opacity-50 z-10  w-screen overflow-y-auto  ">
          <div className="flex min-h-full items-center justify-center p-4">
            <DialogPanel className=" rounded-xl bg-white p-8 shadow-lg transform duration-300 ease-out scale-100 opacity-100">
              <button
                onClick={onClose}
                className="absolute top-0 right-0 p-4 z-50"
              >
                <img src={closeIcon} alt="Close" className="h-3 w-3" />
              </button>
              <div className="flex flex-col items-center justify-center text-center">
                <div className="mt-4">
                  <DialogTitle
                    as="h3"
                    className="text-[26px] font-bold text-black "
                  >
                    Hodlpay is on telegram 🎉
                  </DialogTitle>
                </div>
                <div className="mt-4">
                  <img
                    src={telegram}
                    className="h-[320px] w-[320px] rounded-[10px] "
                    alt="download"
                  />
                </div>
                <div
                  className="bg-blue flex rounded-[10px] px-2 py-4 mt-6 items-center justify-center w-full cursor-pointer"
                  onClick={joinTelegram}
                >
                  <p className="text-[15px] text-white">Join Us</p>
                </div>{" "}
                <div className="mt-6 flex items-center">
                  <input
                    type="checkbox"
                    id="dontShowAgain"
                    className="mr-2"
                    checked={dontShowAgain}
                    onChange={handleCheckboxChange}
                  />
                  <label
                    htmlFor="dontShowAgain"
                    className="text-black text-[19px]"
                  >
                    Don't show again
                  </label>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    );
  };

  const Content = () => {
    return (
      <div className="w-full min-h-[calc(100%-50px)] flex flex-col items-start md:px-0 px-4 ">
        <Banner />

        {profileData !== "Full" && (
          <div className="mt-4">
            <img
              src={kycbanner}
              alt=""
              className="w-full rounded-[10px] cursor-pointer "
              onClick={() => navigate("/kyc")}
            />{" "}
          </div>
        )}

        {challenges && challenges.length > 0 && (
          <div className="mt-8">
            <div className="font-medium text-start text-[18px]">
              Hodlpay Challenges
            </div>
            <img
              src={challenge}
              alt=""
              className="w-full rounded-[10px] cursor-pointer h-[200px]  "
              onClick={() => navigate("/challenges")}
            />{" "}
          </div>
        )}

      

        {JSON.stringify(data.orderStats) === "{}" ? (
          <UserStatsShema />
        ) : (
         
            <div className="flex flex-col md:flex-row w-full gap-6 mt-12  ">
              <Referral />
              <UserStats  stats={data.orderStats}  />
            </div>
        
        )}

        {openNewOrder && (
          <Modal
            close={() => setOpenNewOrder(false)}
            content={
              <NewOrder
                close={(data: orderInfoData | any) => {
                  setShowInfo({
                    status: true,
                    data,
                  });
                  setOpenNewOrder(false);
                }}
              />
            }
          />
        )}

        {showOrderInfo.status && (
          <Modal
            close={() => setShowInfo({ ...showOrderInfo, status: false })}
            content={
              <InfoOrder
                close={() => setShowInfo({ status: false, data: {} })}
                orderInfo={showOrderInfo.data as orderInfoData}
              />
            }
          />
        )}
        {isModalOpen && <MobileUpdateModal onClose={close} />}
      </div>
    );
  };
  return <PageWrapper content={<Content />} />;
}

export default Dashboard;
