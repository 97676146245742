import React, { useState, useEffect, useContext } from "react";
import PageWrapper from "../Components/Wrapper/Wrapper";
import axiosInstance from "../../../setup/axios";
import Constants from "../../../Utils/Constants";
import TwoFactorAuth from "./TwoFactorAuth";
import OtpSuccess from "../Wallet/OtpSuccess";
import DisableModal from "./components/DisableModal";
import OtpForm from "./components/OtpForm";
import Context from "../../../store/Contex";

import Momo from "./Momo";
import Bank from "./Bank";
import SecurityTabs from "./SecurityTabs";

function NewSecurity() {
  const { dispatch } = useContext(Context);

  const [isTwoFactorAuthOpen, setIsTwoFactorAuthOpen] = useState(false);
  const [showSecurity, setShowSecurity] = useState(true);
  const [accessToken, setAccessToken] = useState("");
  const [secret, setSecret] = useState("");
  const [url, setUrl] = useState("");

  const [twoFactorOtp, setTwoFactorOtp] = useState("");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isDisableModalOpen, setIsDisableModalOpen] = useState(false);
  const [showError, setError] = useState("");
  const [isOtpSuccessOpen, setIsOtpSuccessOpen] = useState(false);
  const [isTwoFactorEnabled, setIsTwoFactorEnabled] = useState(false);
  const [showMomo, setShowMomo] = useState(false);
  const [showBank, setShowBank] = useState(false);

  const [paymentMethods, setPaymentMethods] = useState<any>([]);
  const [payoutMethods, setPayoutMethods] = useState<any>([]);

  const displayMomo = () => {
    setShowSecurity(false);
    setShowMomo(true);
  };

  const displayBank = () => {
    // console.log("hi");
    setShowSecurity(false);
    setShowBank(true);
  };
  const getUser = () => {
    axiosInstance
      .get(`${Constants.API_BASE_URL}/users/me`)
      .then((res) => {
        setIsTwoFactorEnabled(res.data.twoFactorEnabled);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  useEffect(() => {
    const data = window.localStorage.getItem("isTwoFactorEnabled");
    if (data !== null) setIsTwoFactorEnabled(JSON.parse(data));
  }, []);

  useEffect(() => {
    getUser();
    window.localStorage.setItem(
      "isTwoFactorEnabled",
      JSON.stringify(isTwoFactorEnabled),
    );
    //eslint-disable-next-line
  }, [isTwoFactorEnabled]);

  const disableTwoFactor = () => {
    axiosInstance
      .post(`${Constants.API_BASE_URL}/auth/user/remove/two-factor`)
      .then((res) => {
        // console.log(res.data.status);
        setIsDisableModalOpen(true);
        setTimeout(() => {
          window.location.href = "/settings";
        }, 1000);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const setUpTwoFactor = () => {
    axiosInstance
      .post(`${Constants.API_BASE_URL}/auth/user/setup/two-factor`)
      .then((res) => {
        setAccessToken(res.data.accessToken);
        setSecret(res.data.secret);
        setUrl(res.data.qrcodeUrl);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const verifyOtp = () => {
    axiosInstance
      .post(`${Constants.API_BASE_URL}/auth/user/verify/two-factor`, {
        token: twoFactorOtp,
        accessToken: accessToken,
      })
      .then((res) => {
        setIsSuccessModalOpen(true);

        setTimeout(() => {
          window.location.href = "/settings";
        }, 1000);
      })
      .catch((err) => {
        //  console.log(err.response.data.message);
        setError(err.response.data.message);
      });
  };

  const goBack = () => {
    setIsTwoFactorAuthOpen(false);
    setShowSecurity(true);
  };

  const handleOtp = (otp: string) => {
    setTwoFactorOtp(otp);
  };

  const getOtp = () => {
    axiosInstance
      .post(`${Constants.API_BASE_URL}/auth/user/phone/otp`, {})
      .then((res) => {
        dispatch({ type: "TURNON", payload: <OtpForm /> });
        // console.log(res);
      })
      .catch((e) => {
        alert("failed");
        // console.log(e);
      });
  };
  const showTwoFactorAuth = () => {
    // console.log("ei");
    setIsTwoFactorAuthOpen(true);
    setShowSecurity(false);
    setUpTwoFactor();
  };

  // const categories = [
  //   {
  //     name: "Account",
  //   },
  //   {
  //     name: "Payment Methods",
  //   },
  //   {
  //     name: "Security",
  //   },
  // ];

  const getPaymentMehtods = () => {
    axiosInstance
      .get(`${Constants.API_BASE_URL}/payments/user?type=PAYMENT`)
      .then((res) => {
        // console.log(res.data);
        setPaymentMethods(res.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const getPayoutMehtods = () => {
    axiosInstance
      .get(`${Constants.API_BASE_URL}/payments/user?type=PAYOUT`)
      .then((res) => {
        // console.log(res.data);
        const banks = res.data.filter(
          (method: any) => method.payment.paymentMethodType === "BANK",
        );

        setPayoutMethods(banks);
        // console.log(payoutMethods);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  useEffect(() => {
    getPaymentMehtods();
    getPayoutMehtods();
  }, []);

  // console.log("payment methods", paymentMethods);
  return (
    <PageWrapper
      content={
        <div className=" flex flex-col items-start md:px-0 px-4 ">
          <div className="text-2xl font-bold ">Settings</div>
          {showSecurity && (
            <SecurityTabs
              paymentMethods={paymentMethods}
              displayMomo={displayMomo}
              displayBank={displayBank}
              payoutMethods={payoutMethods}
              getOtp={getOtp}
              isTwoFactorEnabled={isTwoFactorEnabled}
              showTwoFactorAuth={showTwoFactorAuth}
              disableTwoFactor={disableTwoFactor}
            />
          )}

          {isTwoFactorAuthOpen && (
            <TwoFactorAuth
              verifyOtp={verifyOtp}
              handleOtp={handleOtp}
              twoFactorOtp={twoFactorOtp}
              showError={showError}
              setUpTwoFactor={setUpTwoFactor}
              isSuccessModalOpen={isSuccessModalOpen}
              url={url}
              secret={secret}
              setIsSuccessModalOpen={setIsSuccessModalOpen}
              goBack={goBack}
            />
          )}

          {isOtpSuccessOpen && (
            <OtpSuccess
              isOpen={isOtpSuccessOpen} // Use isOtpModalOpen instead of isForgetModalOpen
              onClose={() => setIsOtpSuccessOpen(false)} // Close the OTP modal
            />
          )}

          {isDisableModalOpen && (
            <DisableModal
              isOpen={isDisableModalOpen}
              onClose={() => setIsDisableModalOpen(false)}
            />
          )}

          {showMomo && (
            <Momo
              setShowMomo={setShowMomo}
              showMomo={showMomo}
              setShowSecurity={setShowSecurity}
              showSecurity={showSecurity}
            />
          )}

          {showBank && (
            <Bank
              showSecurity={showSecurity}
              setShowSecurity={setShowSecurity}
              setShowBank={setShowBank}
              showBank={showBank}
            />
          )}
        </div>
      }
    />
  );
}

export default NewSecurity;
