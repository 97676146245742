import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import Constants from "../../../../Utils/Constants";
import axiosInstance from "../../../../setup/axios";
import copyIcon from "../../../../Common/Assets/Images/Copy.png";
import Notify from "../../../../Helpers/Toast";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share";

function Referral() {
  const { data } = useQuery({
    queryKey: ["user"],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `${Constants.API_BASE_URL}/users/me`,
      );
      return response?.data;
    },
  });

  const [isCopied, setIsCopied] = useState(false);
  const [copiedText, setCopiedText] = useState("");
  const [showShareOptions, setShowShareOptions] = useState(false);

  let signupUrl = "https://app.hodlpay.io/register";
  if (data?.user?.referralCode) {
    signupUrl += `?referrer=${data?.user?.referralCode}`;
  }
  const shareMessage = "Join me on HodlPay and get exclusive rewards!";

  const copyToClipboard = (text: string) => {
    const el = document.createElement("textarea");
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    setCopiedText(text);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <div className="flex flex-col items-start border border-gray-300 rounded-[10px]  md:h-[280px] p-6 w-full">
      <div className="flex md:flex-row flex-col  items-start justify-start md:items-center px-4">
        <div className="flex flex-col items-start">
          <div className="text-gray-500 whitespace-nowrap">Referral ID</div>
          <div className="flex flex-row">
            <div
              onClick={() => copyToClipboard(data?.user?.referralCode || "")}
              className="text-sm  border-gray-20 py-2 flex items-center overflow-hidden cursor-pointer"
            >
              <button className="w-4 h-4 mr-1">
                <img src={copyIcon} alt="Copy" />
              </button>

              {isCopied &&
                copiedText === data?.user?.referralCode &&
                Notify("top-center", "Copied to clipboard!")}
            </div>
            <div>{data?.user?.referralCode}</div>
          </div>
        </div>
        <div className="md:ml-24 flex flex-col items-start">
          <div className="text-gray-500">Referral Link</div>
          <div className="flex ">
            <div
              onClick={() => copyToClipboard(signupUrl)}
              className="text-sm  border-gray-200 py-2 flex items-center overflow-hidden cursor-pointer " 
            >
              <button className="w-4 h-4 mr-1" >
                <img src={copyIcon} alt="Copy" />
              </button>
              {isCopied &&
                copiedText === signupUrl &&
                Notify("top-center", "Copied to clipboard!")}
            </div>
            <p className="ml-2 truncate overflow-hidden w-[150px] ">
              {signupUrl}
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-row items-start mt-5 bg-gray-100 rounded-[10px] p-4 w-full">
        <div className="flex flex-col items-start">
          <div>You Receive</div>
          <div className="font-bold text-[16px]">500 points</div>
        </div>
        <div className="flex flex-col items-start ml-24">
          <div>Points in Cash</div>
          <div className="font-bold text-[16px]">GHS 5</div>
        </div>
      </div>

      <div className="relative w-full">
        <div
          className="bg-blue flex rounded-[10px] px-2 py-4 mt-6 items-center justify-center w-full cursor-pointer"
          onClick={() => setShowShareOptions(!showShareOptions)}
        >
          <p className="text-[15px] text-white">Invite Friends</p>
        </div>

        {showShareOptions && (
          <div className="absolute top-full left-0 right-0 mt-2 bg-white rounded-lg shadow-lg p-2 w-full z-10">
            <div className="flex gap-2 justify-around mb-2">
              <FacebookShareButton url={signupUrl} hashtag={shareMessage}>
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <TwitterShareButton url={signupUrl} title={shareMessage}>
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <WhatsappShareButton url={signupUrl} title={shareMessage}>
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
              <EmailShareButton
                url={signupUrl}
                subject="Join me on HodlPay"
                body={`${shareMessage}\n\n`}
              >
                <EmailIcon size={32} round />
              </EmailShareButton>
              <LinkedinShareButton
                url={signupUrl}
                title="Join HodlPay"
                summary={shareMessage}
              >
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
              <TelegramShareButton url={signupUrl} title={shareMessage}>
                <TelegramIcon size={32} round />
              </TelegramShareButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Referral;
